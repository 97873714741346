import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Clock from 'react-live-clock';
import { theme } from '../global'
import media from '../common/mediaQuery'
import { Anchor } from '../common/anchor'
import { Label } from '../common/type'

class Nav extends Component {

  render() {
    const { anchors, keyEvent, status } = this.props;
  
    return (
      <NavWrapper isOpen={ status }>
        <Items>
          <Timezones>
            <Time>
              <Label as="h3">NYC</Label>
              <Label>
                <Clock format="HH:mm:ss" ticking={true} timezone={'US/Eastern'} />
              </Label>
            </Time>
            <Time>
              <Label as="h3">STO</Label>
              <Label>
                <Clock format="HH:mm:ss" ticking={true} timezone={'Europe/Stockholm'} />
              </Label>
            </Time>
          </Timezones>
          <ListItem>
            <Anchor data-name="about" onClick={ anchors }>About</Anchor>
          </ListItem>
          <ListItem>
            <Anchor data-name="services" onClick={ anchors }>Services</Anchor>
          </ListItem>
          <ListItem>
            <Anchor data-name="platforms" onClick={ anchors }>Platforms</Anchor>
          </ListItem>
          <ListItem>
            <Anchor data-name="contact" onClick={ anchors }>Contact</Anchor>
          </ListItem>
          <Copyright>
            <Label small>© Friend of a Friend NYCSTO 2020.</Label>
          </Copyright>
        </Items>
        <TickerWrapper onKeyDown={ keyEvent } rows="1" placeholder="Friend of a Friend of a Friend of a Friend of a Friend of a Friend of a Friend of a Friend of a Friend of a Friend of a Friend" />
      </NavWrapper>
    );
  }
};

export default Nav

const NavWrapper = styled.div`
  align-items: center;
  background-color: ${theme.colors.bone};
  border-bottom: 1px solid ${theme.colors.green};
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 20px;
  position: fixed;
  transform: translateY(calc(-100% + 50px));
  transition: transform .6s ease-in-out;
  width: 100%;
  z-index: 800;

  ${media.tablet`
    border-bottom: 0;
    border-left: 1px solid ${theme.colors.green};
    flex: 0 0 25%;
    flex-wrap: nowrap;
    height: 100vh;
    order: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
    transform: translateY(0);
  `}

  ${props => props.isOpen && css`
    transform: translateY(calc(0% + 49px));

    ${media.tablet`
      transform: translateY(0);
    `}
  `}
`;

const Timezones = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin: 0 0 34px;
  position: relative;

  ${media.tablet`
    margin: 0 0 68px;
  `}
`;

const Time = styled.div`
  flex: 0 0 48%;

  ${media.tablet`
    position: relative;
  `}

  p {
    margin: 4px 0 0;
  }
`;

const Items = styled.ul`

  ${media.tablet`
    flex: 0 0 100%;
    height: 100vh;
    padding: 30px 25px 0;
    position: relative;
  `}

  ${media.tabletLarge`
    border-right: 1px solid ${theme.colors.green};
    flex: 0 0 70%;
    z-index: 1000;
  `}
`;

const ListItem = styled.li`

  ${media.tablet`
    width: 100%;
  `}

  +li {
    margin: 20px 0 0;

    ${media.tablet`
      margin: 30px 0 0;
    `}

    &:last-of-type {
      margin: 40px 0 0;

      ${media.tablet`
        margin: 0;
      `}
    }
  }
`;

const TickerWrapper = styled.textarea`
  border: 0;
  background-color: transparent;
  color: ${theme.colors.green};
  display: none;
  flex: 0 0 30%;
  font-family: "Neurial Grotesk Regular";
  font-weight: normal;
  margin: 0;
  position: absolute;
  scroll-behavior: smooth;
  text-align: center;
  transform: rotate(90deg);

  ${media.tabletLarge`
    display: flex;
    flex-wrap: nowrap;
    font-size: 3.125rem;
    letter-spacing: -2px;
    line-height: 4.0625rem;
    overflow: scroll;
    right: -157%;
    white-space: nowrap;
    width: 345%;
    z-index: 900;
  `}

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: ${theme.colors.green};
    font-family: "Neurial Grotesk Regular";
    font-size: 1.75rem;
    font-weight: normal;
    letter-spacing: -1px;
    line-height: 2rem;
    margin: 0;

    ${media.tabletLarge`
      font-size: 3.125rem;
      letter-spacing: -2px;
      line-height: 4.0625rem;
    `}
  }
`;

const Copyright = styled.li`

  ${media.tablet`
    bottom: 50px;
    left: 25px;
    position: absolute;
    right: 25px;
  `}
`;