import styled from 'styled-components'
import { theme } from '../global'

export const Anchor = styled.a`
  cursor: pointer;
  font-family: 'Neurial Grotesk Regular';
  font-weight: normal;
  font-size: .875rem;
  letter-spacing: -0.25px;
  line-height: .875rem;
  margin: 0;
  position: relative;
  width: fit-content;

  &:after {
    content: '';
    background-color: ${theme.colors.green};
    bottom: -4px;
    height: 1px;
    left: 0;
    position: absolute;
    right: 100%;
    transition: right 0.4s ease-in-out;
  }

  &:hover {
    /* color: ${theme.colors.gray}; */

    &:after {
      right: 0;
    }
  }
`;
