import styled, { css }  from 'styled-components'
import media from './mediaQuery'


export const H1 = styled.h1`
  font-family: "Neurial Grotesk Regular";
  font-size: 3.125rem;
  font-weight: normal;
  letter-spacing: -2px;
  line-height: 3.125rem;
  margin: 0;

  ${media.tabletLarge`
    font-size: 7.5rem;
    letter-spacing: -7px;
    line-height: 7.5rem;
  `}
`;

export const H2 = styled.h2`
  font-family: "Neurial Grotesk Regular";
  font-size: 1.75rem;
  font-weight: normal;
  letter-spacing: -1px;
  line-height: 2rem;
  margin: 0;

  ${media.tabletLarge`
    font-size: 3.125rem;
    letter-spacing: -2px;
    line-height: 4.0625rem;
  `}
`;

export const Type = styled.p`
  font-family: 'Neurial Grotesk Regular';
  font-weight: normal;
  font-size: .875rem;
  letter-spacing: -0.25px;
  line-height: .875rem;
  margin: 0;

  ${media.tabletLarge`
    font-size: .889rem;
    letter-spacing: -0.5px;
    line-height: .889rem;
  `}
`;

export const Label = styled.p`
  font-family: 'Neurial Grotesk Regular';
  font-weight: normal;
  font-size: .875rem;
  letter-spacing: -0.25px;
  line-height: .875rem;
  margin: 0;
  width: fit-content;

  ${props => props.small && css`

    ${media.tabletLarge`
      font-size: .6875rem;
      line-height: .6875rem;
    `}
  `}
`;

export const Standard = styled.p`
  font-family: 'Neurial Grotesk Regular';
  font-weight: normal;
  font-size: .875rem;
  letter-spacing: -0.25px;
  line-height: .875rem;
  margin: 0;
  width: fit-content;

  ${media.tabletLarge`
    font-size: 1.125rem;
    letter-spacing: -0.5px;
    line-height: 1.125rem;
  `}
`;
