import { createGlobalStyle } from 'styled-components';


export const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    bone: '#FFFEFB',
    gray: '#959595',
    green: '#032100'
  },
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    tabletLarge: '1024px',
    desktop: '1280px'
  },
  sizes: {
    desktop: 1280,
    tabletLarge: 1024,
    tablet: 768,
    tabletPlus: 769,
    phone: 425
  }
};


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Neurial Grotesk Regular";
    src: url('../fonts/NeurialGrotesk-Regular.woff2') format('woff2'), 
         url('../fonts/NeurialGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
    height: 100%;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  *:focus {
    outline: none;
  }
  
  body {
    background-color: ${theme.colors.bone};
    color: ${theme.colors.green};
    margin: 0 auto;
    max-height: 100vh;
    min-height: 100%;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  a {
    cursor: pointer;
    color: ${theme.colors.green};
    position: relative;
    text-decoration: none;

    &:after {
      content: '';
      background-color: ${theme.colors.green};
      bottom: -4px;
      height: 2px;
      left: 0;
      position: absolute;
      right: 100%;
      transition: right 0.4s ease-in-out;
    }

    &:hover {

      &:after {
        right: 0;
      }
    }
  }

  input {
    border-radius: 0;
    -webkit-appearance: none;
    
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.colors.green};
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${theme.colors.green};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${theme.colors.green};
    }

    &[type=email] {
      width: 100%;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  select {
    background: transparent;
  }

  input[type=email] {
    -webkit-appearance: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
  }

  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  @-webkit-keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.colors.bone};
    -webkit-box-shadow: inset 0 0 0px 9999px transparent;
    transition: background-color 5000s ease-in-out 0s;
  }

  @keyframes move-text {
    0% {
      bottom: -1em;
      opacity: 0;
    }

    25% {
      bottom: 0em;
      opacity: 1;
    }

    50% {
      bottom: 0em;
      opacity: 1;
    }

    75% {
      bottom: 0em;
      opacity: 1;
    }

    100% {
      bottom: 0em;
      opacity: 0;
    }
  }
`;
export default GlobalStyle;