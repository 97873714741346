import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../global'
import media from '../common/mediaQuery'
import { Standard } from '../common/type'

class Header extends Component {
  render() {
    const { status, toggle } = this.props;

    return (
      <HeaderWrapper>
        <Toggle isOpen={ status } onClick={ toggle }>
          <ToggleBar className="bar--1"></ToggleBar>
          <ToggleBar className="bar--2"></ToggleBar>
          <ToggleBar className="bar--3"></ToggleBar>
          <ToggleBar className="bar--4"></ToggleBar>
        </Toggle>
        <Logo>
          <Standard>Friend of a Friend</Standard>
        </Logo>
      </HeaderWrapper>
    );
  }
};

export default Header

const HeaderWrapper = styled.div`
  align-items: center;
  background-color: ${theme.colors.bone};
  display: flex;
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;

  ${media.tablet`
    border-bottom: 1px solid ${theme.colors.green};
    height: 80px;
    padding: 0 40px;
    flex-basis: calc(100% * (9 / 12));
    max-width: calc(100% * (9 / 12));
  `}
`;

const ToggleBar = styled.span`
  background-color: ${theme.colors.green};
  display: block;
  height: 1px;
  margin: 5px 0 0;
  transition: .4s ease-in-out;
  width: 20px;

  &:first-of-type {
    margin: 0;
  }

  &.bar {

    &--3 {
      transition: .4s ease-in-out;
      width: 10px;
    }

    &--4 {
      transition: .4s ease-in-out;
      width: 10px;
    }
  }
`;

const Toggle = styled.div`
  cursor: pointer;
  border-right: 1px solid ${theme.colors.green};
  display: flex;
  flex: 0 0 44px;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  opacity: 1;
  padding: 15px;
  justify-content: center;
  transition: .6s ease-in-out;

  ${media.tablet`
    display: none;
  `}

  ${props => props.isOpen && css`
    border-right: 1px solid ${theme.colors.bone};

    .bar--1 {
      transform: rotate(-45deg) translate(-6px, 5px);
      width: 20px;
    }

    .bar--2 {
      opacity: 0;
    }

    .bar--3 {
      transform: rotate(45deg) translate(-3px, -3px);
      width: 20px;
    }

    .bar--4 {
      opacity: 0;
    }
  `}

  &:hover {

    .bar--3 {
      width: 20px;
    }

    .bar--4 {
      width: 20px;
    }
  }
`;

const Logo = styled.div`
  flex: 0 0 auto;
  padding-left: 10px;

  ${media.tablet`
    padding-left: 0;
  `}
`;
