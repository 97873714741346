import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'
import styled from "styled-components"
import GlobalStyle from './global'
import media from './common/mediaQuery'
import Header from './blocks/header'
import Nav from './blocks/nav'

class Layout extends Component {
  state = {
    navOpen: false
  }

  anchorLinks = (e) => {
    const target = e.target.dataset.name;
    document.getElementById(target).scrollIntoView({ 
      behavior: 'smooth'
    });

    if (this.state.navOpen === true) {
      this.setState({ navOpen: false })
    }
  }

  toggleNavigation = () => {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen
    }));
  }

  formEvent = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
    }
  }

  render() { 
    const { children } = this.props;

    return (
      <>
        <GlobalStyle />
        <FlexWrapper>
          <Header status={ this.state.navOpen } toggle={ this.toggleNavigation } />
          <Nav anchors={ this.anchorLinks } keyEvent={ this.formEvent } status={ this.state.navOpen }/>
          <Wrapper>{children}</Wrapper>
        </FlexWrapper>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const FlexWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.tablet`
    flex-wrap: nowrap;
    height: 100vh;
    overflow: hidden;
  `}
`;

const Wrapper = styled.div`
  height: 100%;
  
  ${media.tablet`
    flex: 0 0 75%;
    order: 0;
  `}
`;
